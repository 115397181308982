@font-face {
  font-family: "DIN Web Pro";
  src: url("../fonts/DIN/DINWebPro.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "DIN Web Pro";
  src: url("../fonts/DIN/DINWebPro-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "DIN Web Pro";
  src: url("../fonts/DIN/DINWebPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "DIN Web Pro";
  src: url("../fonts/DIN/DINWebPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

//var
$sm-down: 575px;
$sm-up: 576px;

$md-down: 767px;
$md-up: 768px;

$lg-down: 991px;
$lg-up: 992px;

$xl-down: 1199px;
$xl-up: 1200px;

$red: #c90017;
$red-rgb: rgba(201, 0, 23, 0.7);
$dark-red: #940011;
$grey: #eaeaea;
$dark-grey: #333;

body {
  margin: 0;
  font-family: "DIN Web Pro", Helvetica, Arial, sans-serif;
  font-size: 17px;
  color: $dark-grey;
  h1 {
    font-size: 1.6em;
  }
  h2 {
    font-size: 1.4em;
    &.content__subtitle,
    &.gointSubtitle {
      font-weight: normal;
    }
  }
  h3 {
    font-size: 1.2em;
  }
  h4 {
    font-size: 1.1em;
  }
  h5 {
    font-size: 1em;
  }
  h6 {
    font-size: 0.9em;
  }
  a {
    color: $red;
    border-bottom: 1px dotted;
    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom: 1px solid;
      color: $red;
    }
  }
  p {
    a,
    li a {
      color: $red;
      border-bottom: 1px dotted;
      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom: 1px solid;
        color: $red;
      }
    }
  }
  img {
    max-width: 100%;
  }
  ul {
    li {
      a,
      a:hover,
      a:focus {
        color: $red;
      }
    }
  }
  table,
  .table {
    td,
    th {
      border: 1px solid $dark-grey;
      padding: 5px 10px;
    }
  }
  .figcaption {
    font-size: 0.7em;
    & > span {
      display: block;
      &.figcaption__desc {
        text-align: left;
      }
      &.figcaption__credit,
      &.figcaption-credit {
        text-align: right;
      }
    }
  }
  select.form-control {
    border-radius: 0;
    border: 1px solid $dark-grey;
    font-size: 1em;
    &:hover {
      cursor: pointer;
    }
  }
}

//SVG
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

//BUTTON
.btn {
  &-link {
    border: 1px solid $red;
    border-radius: 0;
    color: $red;
    margin: 15px 0 30px;
    &:hover,
    &:focus {
      background: $red;
      color: #fff;
      text-decoration: none;
      border-color: $red;
    }
  }
  &-big {
    background: $red;
    color: #fff;
    border: none;
    border-radius: 0;
    position: relative;
    padding: 20px;
    &:before {
      content: " ";
      width: 0px;
      height: 0px;
      position: absolute;
      border-right: 20px solid transparent;
      border-left: 10px solid #fff;
      border-top: 10px solid #fff;
      border-bottom: 5px solid transparent;
      right: calc(50% - 15px);
      top: 0;
    }
    &:hover,
    &:focus {
      outline: none;
      color: #fff;
      text-decoration: none;
      border: none;
      background: $dark-red;
      box-shadow: none;
    }
  }
}

//To TOP BTN
.link-to-top-global {
  display: none;
  background: $red;
  position: fixed;
  z-index: 100;
  bottom: 60px;
  right: 20px;
  width: 40px;
  height: 40px;
  & > a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    font-size: 30px;
    border: none;
    .icon {
      transition: transform 0.5s;
      transform: translate3d(0, -2px, 0);
      width: 0.75em;
      height: 0.75em;
    }
    &:hover {
      color: #fff;
      border: none;
      .icon {
        transform: translate3d(0, -8px, 0);
      }
    }
  }
}

//NAV
.navbar-brand {
  border: none;
  &:hover,
  &:focus {
    border: none;
  }
}
nav {
  &.navbar {
    background: $grey;
    @media (max-width: $lg-down) {
      padding-top: 0;
      padding-bottom: 0;
    }
    .navbar-collapse {
      position: relative;
    }
  }
  .navbar {
    &-brand {
      margin: 0;
    }
    &-toggler {
      border: none;
      position: absolute;
      right: 15px;
      top: -50px;
      &:focus {
        outline: none;
      }
      &-bar {
        display: block;
        height: 3px;
        width: 30px;
        background: $red;
        margin-bottom: 6px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        &:nth-of-type(1) {
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          -webkit-transform-origin: 0% 0%;
          -ms-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
        }
        &:nth-of-type(2) {
          opacity: 0;
          filter: alpha(opacity=0);
        }
        &:nth-of-type(3) {
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          -webkit-transform-origin: 10% 100%;
          -ms-transform-origin: 10% 100%;
          transform-origin: 10% 100%;
        }
      }
      &.collapsed {
        span {
          &:nth-of-type(1) {
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
            transform: rotate(0);
          }
          &:nth-of-type(2) {
            opacity: 1;
            filter: alpha(opacity=100);
          }
          &:nth-of-type(3) {
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
            transform: rotate(0);
          }
        }
      }
    }
    &-nav {
      position: relative;
      li {
        padding: 0 10px;
        position: static;
        @media (min-width: $lg-up) and (max-width: $xl-down) {
          padding: 0 5px;
        }
        @media (max-width: $lg-down) {
          padding: 0;
        }
        &:first-child {
          padding-left: 0;
        }
        &.nav-item {
          &.active {
            color: $red;
            .disabled {
              color: $red;
            }
          }
        }
        a {
          font-size: 1.1em;
          font-weight: 500;
          color: inherit;
          border: none;
          @media (min-width: $lg-up) and (max-width: $xl-down) {
            font-size: 1em;
          }
          &:hover,
          &:focus {
            color: $red;
          }
        }
        &.dropdown {
          & > a {
            background: url("../assets/arrow-down.svg") no-repeat right;
            background-size: 0.75em;
            @media (min-width: $lg-up) and (max-width: $xl-down) {
              background-size: 0.6em;
            }
            &.nav-link {
              padding-right: 20px;
              @media (min-width: $lg-up) and (max-width: $xl-down) {
                padding-right: 15px;
              }
            }
            &:after {
              display: none;
            }
            &:hover,
            &:focus {
              background: url("../assets/arrow-down-red.svg") no-repeat right;
              background-size: 0.75em;
              @media (min-width: $lg-up) and (max-width: $xl-down) {
                background-size: 0.6em;
              }
            }
          }
          &.show {
            & > a {
              color: $red;
              background: url("../assets/arrow-up-red.svg") no-repeat right;
              background-size: 0.75em;
              @media (min-width: $lg-up) and (max-width: $xl-down) {
                background-size: 0.6em;
              }
            }
            &.active {
              & > a {
                background: url("../assets/arrow-up-red.svg") no-repeat right;
                background-size: 0.75em;
              }
            }
          }
          &.active {
            & > a {
              background: url("../assets/arrow-down-red.svg") no-repeat right;
              background-size: 0.75em;
            }
          }
        }
      }
      .dropdown-menu {
        border: none;
        background: #fff;
        border-radius: 0;
        margin-top: 0.5rem;
        a {
          font-size: 1em;
          padding: 10px 15px;
          &:before {
            @media (max-width: $lg-down) {
              content: "- ";
            }
          }
          &:hover,
          &:focus,
          &.active {
            background: none;
            color: $red;
          }
        }
        @media (min-width: $sm-up) {
          width: 540px;
        }
        @media (min-width: $md-up) {
          width: 720px;
        }
        @media (min-width: $lg-up) {
          width: 960px;
        }
        @media (min-width: $xl-up) {
          width: 1140px;
        }
      }
    }
    &-meta {
      @media (min-width: $lg-up) {
        position: absolute;
        right: 0;
        top: -60px;
      }
      ul {
        padding: 0;
        li {
          list-style: none;
          a {
            color: inherit;
            border: none;
            &:hover,
            &:focus {
              color: $red;
            }
          }
        }
      }
    }
  }
  form.form-inline {
    @media (max-width: $lg-down) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .form-control {
      border: none;
      border-radius: 0;
      margin: 0 !important;
      padding-right: 0px;
      width: 40px;
      /*-webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;
      @media (min-width: $lg-up) {
        &:focus {
          width: calc(100% - 40px);
        }
      }*/
      @media (min-width: $lg-up) {
        width: 130px;
      }
      @media (min-width: $xl-up) {
        width: 180px;
      }
      @media (max-width: $lg-down) {
        width: calc(100% - 40px);
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
    .btn {
      border: none;
      border-radius: 0;
      background: #fff;
      height: 38px;
      svg {
        fill: inherit;
      }
    }
  }
  .breadcrumb {
    background: none;
    border-radius: 0;
    border: 1px solid $grey;
    border-left: 0;
    border-right: 0;
    font-size: 0.9em;
    padding-left: 0;
    padding-right: 0;
    &-item + .breadcrumb-item:before {
      content: ">";
    }
    li {
      &.active {
        color: inherit;
      }
      a {
        color: $red;
        border: none;
        &:hover,
        &:focus {
          border-bottom: 1px solid;
        }
      }
    }
  }
}

//MAIN
.tfn {
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-in-out;
}
.intro {
  @media (min-width: $lg-up) {
    background: url("../assets/banner2.jpg");
    background-size: cover;
  }
  .container {
    .offset-lg-6 {
      @media (min-width: $lg-up) {
        background: rgba(255, 255, 255, 0.8);
        padding: 15px;
      }
    }
  }
}
main {
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
    &-search {
      .search__entry {
        border-top: 1px solid $grey;
        margin: 20px 0;
        padding-top: 20px;
        .path {
          font-size: 0.8em;
          span {
            display: inline-block;
            margin-right: 5px;
            &:after {
              content: " > ";
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
        a {
          display: block;
          color: inherit;
          &:hover,
          &:focus {
            text-decoration: none;
            h4 {
              color: $red;
              text-decoration: underline;
            }
          }
        }
        &.foerderthema {
          a {
            &:hover,
            &:focus {
              .path {
                span {
                  color: inherit;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      .form-inline {
        input {
          width: calc(100% - 45px);
          border-radius: 0;
          border: 1px solid $dark-grey;
          border-right: 0;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        button {
          border-radius: 0;
          border: 1px solid $dark-grey;
          border-left: 0;
          &:hover,
          &:focus {
            background: none;
            box-shadow: none;
            svg {
              fill: $red;
            }
          }
          svg {
            fill: inherit;
          }
        }
        & > div {
          width: 100%;
          margin-top: 5px;
          a {
            color: $red;
          }
        }
      }
    }
  }
}
.content {
  figure {
    img {
      width: 100%;
    }
  }
  &__meta__share {
    overflow: visible;
    .social-links {
      padding: 0;
      margin: 0;
      overflow: hidden;
      li {
        list-style: none;
        float: left;
        transition: transform 0.25s;
        transform: scale(1);
        &:hover {
          transform: scale(1.2);
        }
        a {
          padding: 0 15px;
          font-size: 1.3em;
          border: none;
          &:first-child {
            padding-left: 0;
          }
          &:hover,
          &:focus {
            text-decoration: none;
          }
          svg {
            fill: inherit;
          }
        }
      }
    }
  }
  a {
    color: $red;
    border-bottom: 1px dotted;
    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom: 1px solid;
    }
    &.btn {
      &.btn-big {
        background: $red;
        border: none;
        color: #fff;
        &:hover,
        &:focus {
          background: $dark-red;
        }
      }
      &.btn-link {
        background: #fff;
        border: 1px solid;
        color: $red;
        margin: 0;
        &:hover,
        &:focus {
          background: $red;
          border-color: $red;
          color: #fff;
        }
      }
    }
  }
}

//ASIDE
aside {
  .teaser {
    height: auto;
    &__content {
      position: static;
      padding: 20px;
      height: auto;
      background: $grey;
      color: inherit;
    }
    .figcaption__credit {
      bottom: 15px;
      font-size: 0.7em;
      color: lightgrey;
      display: block;
      text-align: right;
    }
    a {
      &:hover,
      &:focus {
        h3 {
          color: $red;
        }
      }
    }
  }
}

//TEASER

.ellip {
  display: block;
  height: 100%;

  position: relative;
  overflow: hidden;
  max-width: 100%;

  width: 100%;

  &-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;

    position: relative;
    overflow: hidden;
    max-width: 100%;
  }
}

.two-teaser-row {
  .teaser {
    &__category {
      @media (min-width: $sm-up) and (max-width: $md-down) {
        font-size: 0.9em;
      }
    }
    &--highlight {
      &__title {
        @media (min-width: $sm-up) and (max-width: $md-down) {
          font-size: 1em;
        }
      }
    }
  }
}
.teaser {
  position: relative;
  overflow: hidden;
  a {
    color: $dark-grey;
    &:hover,
    &:focus {
      text-decoration: none;
      h3,
      h3 span {
        text-decoration: underline;
      }
    }
  }
  & > a {
    display: block;
    border: none;
    &:hover,
    &:focus {
      border: none;
    }
  }
  @media (min-width: $md-up) {
    height: 360px;
  }
  @media (min-width: $lg-up) {
    height: 401px;
  }
  @media (min-width: $xl-up) {
    height: 302px;
  }
  margin: 20px auto;
  &__category {
    color: initial;
    background: #fff;
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 10px 10px 6px;
    margin: 0;
    display: inline-block;
    font-size: 1em;
    font-weight: normal;
  }
  &--highlight {
    &__title {
      font-size: 1.2em;
    }
  }
  &__image {
    .figcaption__credit {
      position: relative;
      bottom: 15px;
      text-align: right;
      background: rgba(255, 255, 255, 0.6);
      color: inherit;
      float: right;
      padding: 0 3px;
      font-size: 0.6em;
      @media (min-width: $xl-up) {
        bottom: 140px;
      }
    }
  }
  &__img {
    width: 100%;
    height: auto;
  }
  &__content {
    @media (min-width: $xl-up) {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      height: 125px;
    }
    min-height: inherit;
    background: $red-rgb;
    color: #fff;
    width: 100%;
    padding: 10px;
  }
  &__contact {
    img {
      border-radius: 50%;
    }
    span {
      display: block;
    }
    &-global {
      h4 {
        text-transform: uppercase;
      }
      .org {
        font-weight: 500;
      }
      .name {
        font-weight: normal !important;
        margin: 0 !important;
      }
    }
    select {
      margin-bottom: 20px;
    }
    .org {
      font-weight: 500;
    }
  }
  &--default {
    .teaser {
      &__content {
        .link-list {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            border-top: 2px solid #fff;
            padding: 10px;
            &:last-child {
              border-bottom: 2px solid #fff;
            }
            a {
              border: none;
              &:hover,
              &:focus {
                color: $red;
              }
              svg {
                width: 0.8em;
                height: 0.8em;
              }
            }
          }
        }
      }
    }
    &_download {
      li {
        padding-left: 0 !important;
        position: relative;
        svg {
          position: absolute;
          right: 3px;
          top: calc(50% - 17px);
        }
        .content-download__type {
          position: absolute;
          right: 0;
          font-size: 0.7em;
          font-weight: 600;
          top: 50%;
        }
      }
    }
    &_contact,
    &_company {
      .teaser__content {
        background: #fff;
      }
    }
    &_company {
      a {
        border-bottom: 1px dotted;
        &:hover,
        &:focus {
          border-bottom: 1px solid;
          color: $red;
        }
      }
    }
  }
}

// TEAM
.row-team {
  border: 1px solid $red;
  position: relative;
  margin: 0 0 30px 30px;
  width: calc(100% - 30px);
  @media (max-width: $lg-down) {
    margin-left: 0;
    width: 100%;
  }
  &:before {
    content: " ";
    width: 0px;
    height: 0px;
    position: absolute;
    border-right: 30px solid transparent;
    border-left: 20px solid $red;
    border-top: 15px solid $red;
    border-bottom: 10px solid transparent;
    right: calc(50% - 50px);
    top: 0;
  }
  &:after {
    content: " ";
    width: 0px;
    height: 0px;
    position: absolute;
    border-right: 30px solid transparent;
    border-left: 20px solid #fff;
    border-top: 15px solid #fff;
    border-bottom: 10px solid transparent;
    right: calc(50% - 51px);
    top: -1px;
  }
  .team {
    text-align: center;
    height: 100%;
    padding: 30px 30px 0 30px;
    &:first-child {
      @media (min-width: $md-up) and (max-width: $lg-down) {
        padding-bottom: 30px;
      }
    }
    &:last-child {
      padding-bottom: 30px;
    }
    &__description {
      font-size: 0.85em;
    }
    &__image {
      width: 100%;
      img {
        border-radius: 50%;
        max-width: 150px;
      }
    }
    &__name {
      font-weight: 500;
    }
    span {
      display: block;
      &.figcaption__credit {
        margin-bottom: 10px;
        font-size: 0.6em;
      }
    }
  }
}

//FÖRDERUNGEN
.row-foerderungen-suche {
  select {
    @media (min-width: $lg-up) {
      width: 770px;
    }
  }
  .form-group {
    @media (min-width: $lg-up) {
      display: inline-block;
      margin-right: 50px;
    }
    &:first-child {
      @media (min-width: $lg-up) {
        display: block;
        margin-right: 0;
      }
    }
  }
  .form-check {
    &-input {
      margin-left: 0;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border: 1px solid;
      &:checked {
        &:before {
          content: "";
          display: block;
          width: 8px;
          height: 16px;
          border: solid #000;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          margin-left: 5px;
        }
      }
    }
    &-label {
      margin-left: 15px;
    }
    &-input,
    &-label {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .icon {
    margin: 0 10px 0 20px;
    height: 1.2em;
    width: 1.2em;
  }
}
.foerderthemen {
  margin-bottom: 50px;
  img {
    margin-bottom: 10px;
  }
  .figcaption__credit {
    display: inline-block;
    float: right;
    color: $dark-grey;
    background: rgba(255, 255, 255, 0.5);
    padding: 3px 5px;
    margin-top: -33px;
    position: relative;
    font-size: 0.7em;
  }
  p {
    margin-bottom: 10px;
  }
  a {
    color: $red;
  }
}
.foerderthema {
  border-top: 1px solid $grey;
  margin-bottom: 50px;
  a {
    display: block;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: none;
      h4,
      span {
        text-decoration: underline;
        color: $red;
      }
    }
    &.btn {
      color: $red;
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
  &__category {
    display: inline-block;
    font-size: 0.8em;
    background: $grey;
    padding: 5px 10px;
    margin-bottom: 20px;
  }
  &__entry {
    margin-bottom: 30px;
  }
  &__content {
    margin-bottom: 0;
  }
}

.more-events,
.support-market {
  & > .icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
    float: left;
  }
  h4 {
    line-height: 1.6;
  }
}
.more-events {
  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      h5,
      span {
        text-decoration: underline;
        color: $red;
      }
    }
  }
  span {
    margin-left: 10px;
    color: $red;
  }
}

//FOOTER
footer {
  background: $grey;
  font-size: 0.75em;
  text-align: center;
  .container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .copy-year {
    margin: 0;
  }
  a {
    display: inline-block;
    margin: 0 15px;
    border: none;
    &:hover,
    &:focus {
      border: none;
    }
    @media (max-width: 480px) {
      display: block;
      margin: 15px 0 30px;
    }
  }
  img {
    max-width: 150px;
  }
  ul {
    padding: 0;
    margin: 30px 0 0;
    li {
      display: inline;
      padding-left: 5px;
      @media (max-width: $md-down) {
        display: block;
        padding: 5px 0;
      }
      &:after {
        content: "|";
        padding-left: 10px;
        @media (max-width: $md-down) {
          display: none;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: inherit;
        margin: 0;
        &:hover,
        &:focus {
          color: $red;
          text-decoration: none;
        }
      }
    }
  }
}

//BRICKLAYER
.bricklayer {
  &-column {
    padding: 0;
    &-sizer {
      width: 100%;
      @media (min-width: $lg-up) {
        width: 50%;
      }
      & + .bricklayer-column {
        width: 66.666666%;
        flex: unset;
        padding-right: 30px;
        @media (max-width: $lg-down) {
          width: 100%;
          padding-right: 0;
        }
        & + .bricklayer-column {
          max-width: 33.333333%;
        }
      }
    }
  }
  .teaser {
    width: 100%;
    height: auto;
    margin: 20px 0;
    @media (min-width: $lg-up) {
      width: 555px;
      height: 302px;
    }
    &__content {
      @media (min-width: $lg-up) {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 125px;
      }
    }
    .figcaption__credit {
      @media (min-width: $lg-up) {
        bottom: 140px;
      }
    }
  }
}

.popup__area
  #selectarea
  #areas
  form
  > .col-sm-6:nth-child(2)
  > .radio:last-child
  label {
  visibility: hidden;
}
#areas .col-sm-6 {
  float: left;
}
.toggle.btn-primary {
  background: transparent;
}
.popup__cookie #cookiesettings .toggle-group .btn.toggle-handle {
  left: 0;
}

.panel {
  margin-bottom: 24px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 0;
  .panel-body {
    padding: 20px;
  }

  &.panel-no-border {
    border: none;
    > .panel-heading {
      display: inline-block;
      padding: 8px 8px 6px 8px;
      border-bottom: 0;
      line-height: 1;
    }
    > .panel-body {
      padding: 15px 0 15px 20px;
    }
  }

  &.panel-primary {
    border-color: #304c59;
    > .panel-heading {
      color: #fff;
      background-color: #304c59;
      border-color: #304c59;
    }
  }

  &.panel-danger {
    border-color: #e20613;
    > .panel-heading {
      color: #fff;
      background-color: #e20613;
      border-color: #e20613;
    }
  }
}

blockquote {
  font-style: italic;
  margin: 20px 0;
  padding: 20px;
  font-size: 21px;
  border-left: 4px solid #eaeaea;
  footer,
  small,
  .small {
    background-color: transparent;
    font-style: normal;
    display: inline-block;
    font-size: 80%;
    line-height: 1.42857;
    color: #8c8c8c;
    ::before {
      content: "\2014 \00A0";
    }
  }
}

.faqs .panel-group > ol::marker {
  font-weight: bold;
}

/* 2.19 Barrierefreiheit */
.sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.skippy {
  font-size: 1rem;
  color: #000;
  padding: 0 1.5rem !important;
  margin: 4rem 1rem 0 1rem !important;
  border: 1px solid #000;
}
